import type { DataObject, DataItemModel, ILayoutColumnProperties } from 'o365-dataobject';
import type { EventArgType, BaseSelectionControl } from 'o365-modules';
import type DataColumns from './DataGrid.DataColumns.ts';
import type { DataColumnOptions } from './DataGrid.DataColumn.ts';
import type DataGridMenuControl from './DataGrid.GridMenu.ts';
import type DataGridHeader from './DataGrid.Header.ts';
// temp dts fix
// import type { GridFocusControl_Compatibility, NavigationControl_Compatibility, GridSelectionInterface_Compatibility} from './extensions.Navigation.ts';

// import CellSelectControl from 'o365.controls.DataGrid.CellSelectControl.js'; // Deleted file
import DataColumn from './DataGrid.DataColumn.ts';
import { EventEmitter, localStorageHelper } from 'o365-modules';
import DataSelectionControl from './DataGrid.DataSelectionControl.ts';
import { FilterObject } from 'o365-filterobject';
import 'o365.libraries.modules.DataObject.extensions.BatchData.ts';
import { addEventListener } from 'o365-vue-utils';
import { logger } from 'o365-utils';
import DataGridColumnMove from './DataGrid.ColumnMove2.ts';
import { nextTick } from 'vue';

const dataGridControlStore = new Map<string, DataGridControl>();

/** Returns the grid control with the given id */
export function getDataGridControlById(pId: string) {
    if (dataGridControlStore.has(pId)) {
        return dataGridControlStore.get(pId) as DataGridControl;
    } else {
        return null;
    }
}

/**
 * Retruns the grid control for the given DataObject if no unique id is set on the grid
 */
export function getDataGridControlByDataObject(pDataObject: DataObject) {
    const id = getDataGridIdFromDataObject(pDataObject);
    return getDataGridControlById(id);
}

/** Global active grid */
let activeGridId: string | null = null;
// Global click handler for auto saving changes in grids when clicking outside of active row 
addEventListener(window, 'click', (pEvent) => {
    const target = pEvent.target as HTMLElement;
    if (target == null || target.closest == null) { return; }

    // '[role="dialog"]', '[data-grid-master-id]'
    const filterSelectors = ['[data-grid-skip-click-handler]', '.o365-data-lookup', '.toast', '.o365-editor-popup', '.c-bottom-sheet-modal', '.c-bottom-sheet-backdrop']; // Skip handler if target is inside any of these selectors
    // const filterSelectors = ['.o365-data-lookup', '.toast', '.o365-editor-popup', '.c-bottom-sheet-modal', '.c-bottom-sheet-backdrop']; // Skip handler if target is inside any of these selectors
    if (target.closest(filterSelectors.join(', ')) != null) {
        return;
    } else if (activeGridId) {
        const masterGridId = target.closest<HTMLElement>('[data-grid-master-id]')?.dataset.gridMasterId;
        const closestGridId = target.closest<HTMLElement>('.o365-data-grid')?.id;
        if (masterGridId === activeGridId && (closestGridId == null || closestGridId === masterGridId)) {
            return;
        } else if (target.parentElement == null) {
            // Element got removed in the click event
            return;
        } else if (masterGridId && masterGridId != activeGridId) {
            let currentId: string|undefined = masterGridId;
            let skipHandler = false;
            while (currentId) {
                const control = getDataGridControlById(currentId);
                if (control == null) {
                    currentId = undefined;
                } else {
                    currentId = control.masterGridId;
                }
                if (currentId == activeGridId) {
                    currentId = undefined;
                    skipHandler = true;
                }
            }
            if (skipHandler) {
                return;
            }
        }
    }
    const gridElement = target.closest('.o365-data-grid') as HTMLElement;

    const savePrevious = () => {
        if (activeGridId) {
            const previousDataGridControl = getDataGridControlById(activeGridId);
            if (previousDataGridControl) {
                const skipSave = previousDataGridControl.props.isLookup || previousDataGridControl.dataObject == null || previousDataGridControl.gridFocusControl == null || previousDataGridControl.props.disableSaveOnOutsideClicks;
                if (!skipSave) {
                    if (previousDataGridControl.dataObject!.storage.hasChanges || (previousDataGridControl.dataObject!.batchDataEnabled && previousDataGridControl.dataObject!.batchData.storage.hasChanges)) {
                        previousDataGridControl.dataObject!.save();
                    }
                    if (previousDataGridControl.gridFocusControl!.editMode && previousDataGridControl.hasNavigation && !previousDataGridControl.navigation.mouseDownElement) {
                        previousDataGridControl.gridFocusControl!.exitEditMode();
                        previousDataGridControl.gridFocusControl!.clearFocus();
                    }
                }
            }
            activeGridId = null;
        }
    };

    if (gridElement) {
        const gridId = gridElement.id;
        const dataGridControl = getDataGridControlById(gridId);
        if (dataGridControl) {
            if (dataGridControl.props.isLookup || dataGridControl.props.disableSaveOnOutsideClicks) { return; }
            if (activeGridId == null || activeGridId === gridId) {
                if (dataGridControl.dataObject == null || dataGridControl.gridFocusControl == null || dataGridControl.gridFocusControl.activeCellLocation == null) { return; }
                let saveChanges = true;
                let exitEdit = true;
                const rowEl = target.closest('[data-o365-rowindex]') as HTMLElement;
                if (rowEl && rowEl.dataset.o365Rowindex != null) {
                    exitEdit = false;
                    const rowIndex = parseInt(rowEl.dataset.o365Rowindex);
                    const activeIndex = dataGridControl.gridFocusControl.activeCellLocation.y;
                    saveChanges = rowIndex != activeIndex;
                }
                if (exitEdit && dataGridControl.hasNavigation && !dataGridControl.navigation.mouseDownElement) {
                    dataGridControl.gridFocusControl.exitEditMode();
                    dataGridControl.navigation.resolveFocus({ clearFocus: true});
                }
                if (saveChanges && (dataGridControl.dataObject.storage.hasChanges || (dataGridControl.dataObject.batchDataEnabled && dataGridControl.dataObject.batchData.storage.hasChanges))) {
                    dataGridControl.dataObject!.save();
                }
            } else {
                savePrevious();
            }
            activeGridId = dataGridControl.id;
        } else if (activeGridId) {
            savePrevious();
        }
    } else if (activeGridId) {
        savePrevious();
    }
}, {
    passive: true
});

export default class DataGridControl {
    id: string;
    dataColumns: DataColumns;
    /** DataObject bound to this grid instance */
    dataObject?: DataObject;
    /** The main list viewport containing pinned and center columns */
    viewPort: HTMLElement | null;
    /** Debounce for column watch functions */
    columnWatchDebounce: number | null = null;
    /** Locking promises that must be resolved before a layout is saved */
    layoutSaveLocks: Promise<void>[] = [];
    /** The root container element of the grid */
    container: HTMLElement | null;
    /** The main grid body (between the header and status bar) */
    scopedContainer?: HTMLElement;
    /** Control responsible for column ordering through drag events */
    columnMove?: DataGridColumnMove;
    /** Grid event emitter */
    eventHandler: EventEmitter<DataGridEvents>;
    /** Selection interface between the data */
    gridSelectionInterface?: any // GridSelectionInterface_Compatibility;
    /** Focus control responsible for keeping track and controling current focussed cell */
    gridFocusControl?: any // GridFocusControl_Compatibility;
    /** Collection of utility functions from the main list navigation composable */
    gridNavigationControl?: any // NavigationControl_Compatibility;
    /** Interface between grid and DataObject.groupBy module */
    groupBy?: never;
    /** Group by promise, used in order to only register group by once */
    private _groupByPromise?: Promise<void>;
    /** Update the viewport widths with debounce */
    updateViewport?: Function;
    /** Force update scroll data (shown items length, positions and values) */
    updateVirtualScrollData?: Function;
    /** Get the main vertical scroll viewport */
    getVerticalScrollViewport?: () => HTMLElement | undefined;
    /** Get item from scrol data by scroll index. Scroll indexes can differ from data indexes. */
    getItemByScrollIndex?: (pIndex: number) => any;
    newrecordsRef: any | null = null;
    /** Current state of the grid. If a DataObject is provided then refrences to DataObject.State will be used instead of the internal values. */
    state: DataGridState;
    /** When true will render the sidepanel on the left side */
    // leftSidepanel: boolean = false;
    get leftSidepanel() { return false;} // By Sigmunds request forcing right side for a couploe of days 
    set leftSidepanel(_) {}
    /** Indicates that the vertical scroll bar is shown */
    showHeightScrollbar = true;
    /** Last known viewport height */
    viewportHeight: number | null = null;
    /** Array of cancel tokens that will be called on grid destruction */
    private _cancelTokens: (() => void)[] = [];
    /** Selection control for grids without data objects */
    private _selectionControl?: DataSelectionControl;
    /** Filter object for grids without data objects */
    private _filterObject?: FilterObject;
    /** Helper class for new items in unbound grids */
    private _newArrayData?: ArrayNewData;
    /** Unique id for this grid instance, used when selecting elements inside the grid */
    private uid!: string;
    /** Current active watch target type used to update virtual scroll */
    private _currentWatchTargetType!: DataGridValueWatcherType;
    /** Main list is observed for height changes with debounce */
    private _isObserved: boolean = false;
    /** Indicates that the grid container has an active intersection observer */
    private _intersectionsObserved: boolean = false;
    /** Last ScrollItem index that was multiselected by user */
    private _lastMultiSelectIndex?: number;
    /** Master grid id */
    private _masterGridId?: string;
    private _isFileTable = false;
    private _newRecordsPosition?: IDataGridControlProps['newRecordsPosition'];
    private _notVisible = false;
    /** Emit function from main vue component */
    private _vueEmit: Function;
    /** Cancel token for DataLoaded event on the DataObject */
    private _dataObjectCleanupTokens: Function[] = [];
    /** Used to cancel any ongoing rowcount requests when data object is reloaded */
    private _rowCountRequestOptions?: any;
    /** Indicates that this control is for DataTable */
    isTable = false;
    /** Collection of utilities added by diffrent modules on the grid */
    utils = new DataGridUtils();
    /** When true will enable importData */
    importData: boolean = false; // TODO: Move to props?
    /** When true will enable importData */
    importDataBatch: boolean = false; // TODO: Move to props?
    importDataProps:object={};
    /** Function added by the footer. Used to trigger applied summary aggregates update  */
    updateSummaryAggregates?: () => void;
    /** Last known scroll left of the grid */
    lastKnownWidthScroll?: number;
    /** Indicates that the new records panel was activated by default */
    autoNewRecordsPanel = false;
    virtualScrollApi?: {
        getRowHeightByIndex: (pIndex: number) => number;
        getPosByIndex: (pIndex: number) => number;
        totalHeight: { value: number };
        updateWatcher: (pSkipDynamicLoading?: boolean) => void;
    };

    _sortOrderChanged?: boolean;
    _sortOrderChangedCt?: () => void;

    private _cancelAutoNewRow?: () => void;

    /** Header module */
    header?: DataGridHeader;

    props: IDataGridControlProps;

    get onDemandFields() {
        return this.props.onDemandFields;
    }
    get multilineHeader() {
        return this.props.multilineHeader;
    }
    get createNewOverrideFn() {
        return this.props.createNewOverrideFn;
    }
    get fieldFilters() {
        return this.props.fieldFilters;
    }
    get groupColumnDefinition() {
        return this.props.groupColumnDefinition;
    }
    get noHeaderRow() {
        return this.props.noHeaderRow
    }

    /** Selection control either from the DataObject of Grid control */
    get selectionControl(): BaseSelectionControl | undefined {
        return this.dataObject?.selectionControl ?? this._selectionControl;
    }

    /** Helper class for new records when no data object is bound to the grid */
    get newData() {
        if (this.dataObject || this.props.gridApi?.createNew == null) { return null; }
        if (!this._newArrayData) {
            this._newArrayData = new ArrayNewData({
                dataGridControl: this
            });
        }
        return this._newArrayData;
    }

    get filterObject(): FilterObject {
        if (this.dataObject) {
            return this.dataObject.filterObject;
        } else if (!this._filterObject) {
            this._filterObject = new FilterObject({
                dataObject: {
                    recordSource: {
                        filterString: null,
                        clearFilter() { this.filterString = null; }
                    },
                    load: () => { logger.warn('Filter composable not initated'); },
                    setIndexForFiltersList: () => { logger.warn('FilterList not implemented for array data') },
                },
                columns: [...this.dataColumns.columns],
            });
        }
        return this._filterObject;
    }

    get currentWatchTargetType() {
        return this._currentWatchTargetType;
    }

    get current() {
        if (this.dataObject) {
            return this.dataObject.current;
        } else if (this.props.data) {
            if (this.state.currentIndex == null) { return {}; }
            return this.utils?.processedData
                ? this.utils.processedData[this.state.currentIndex]
                : this.props.data[this.state.currentIndex]
        } else {
            throw new Error('No dataobject or data array provided to the grid');
        }
    }

    get masterGridId() { return this._masterGridId}

    get showNewRecordsPanel() {
        if (this.props.showNewRecordsPanel != undefined) {
            return !!this.props.showNewRecordsPanel;
        } else {
            return this.isFileTable ? false : true;
        }
    }

    /** Indicates that the grid is a file table. New records panel will not be shown by default */
    get isFileTable() { return this._isFileTable; }

    get newRecordsPosition() {
        return this._newRecordsPosition ?? this.props.newRecordsPosition;
    }

    /** Indicates that the grid is currently not visible in the viewport. Some functionality is disabled when this is true */
    get notVisible() { return this._notVisible; }

    /** Emit a defined vue event on the DataGrid/DataTable component */
    get vueEmit() { return this._vueEmit; }

    /**
    * Menu control for the sidepanel. Is created from the GridMenu ponent
    */
    menuTabs?: DataGridMenuControl;

    /**
     * The current state of the width scroll
     */
    widthScrollState: DataGridWidthScrollState = DataGridWidthScrollState.None;

    isScrollingFromScrollbar: boolean = false;
    isScrollingFromMainContainer: boolean = false;

    /** Resize observer used to update the grid viewport when resized */
    private _resizeObserver?: ResizeObserver;
    /** Intersection observer for detecting if the grid is currently visible or not */
    private _intersectionObserver?: IntersectionObserver;

    isColumnSystem(colId: string) {
        return DataGridControl.isColumnSystem(colId);
    }

    static isColumnSystem(colName: string) {
        switch (colName) {
            case 'o365_Action':
            case 'o365_MultiSelect':
            case 'o365_System':
                return true;
            default:
                return false;
        }
    }

    constructor(props: IDataGridControlProps, options: {
        id?: string,
        masterGrid?: string,
        columns: DataColumns,
        emit: Function,
    }) {
        if (options.id != null) {
            this.id = options.id;
        } else if (props.dataObject) {
            this.id = getDataGridIdFromDataObject(props.dataObject);
        } else {
            this.id = `dynamicGrid_${crypto.randomUUID()}`;
        }
        this._masterGridId = options.masterGrid;
        this.props = props;
        this.dataObject = props.dataObject;
        this.dataColumns = options.columns;
        this.dataColumns.updateColumnArrays();
        this.viewPort = null;
        this.container = null;
        this.eventHandler = new EventEmitter();
        this.state = new DataGridState(props.dataObject);
        this._vueEmit = options.emit;
        try {
            const hasLeftSidePanel = localStorageHelper.getItem('datagrid-left-sidemenu', { global: false });
            this.leftSidepanel = hasLeftSidePanel === 'true' || hasLeftSidePanel === '';
            // this.leftSidepanel = hasLeftSidePanel === 'true';
        } catch (ex) {
            console.error(ex);
            this.leftSidepanel = false;
        }

        try {
            const newRecordsPositionOverride = localStorageHelper.getItem('datagrid-newrecords-position', {
                global: false,
                expirationDays: 30
            });
            if (newRecordsPositionOverride) {
                this._newRecordsPosition = newRecordsPositionOverride as any;
            }
        } catch (ex) {
            console.error(ex);
        }

        this.importData = (props.importDataBatch || props.importData) ?? false;
        this.importDataBatch = props.importDataBatch ?? false;
        this.importDataProps = props.importDataProps?? {}

        if (this.dataObject) {
            if (this.props.persistentFilterId) {
                this.filterObject.persistentFilterId = this.props.persistentFilterId;
            } else if (this.dataObject.metadata?.isFromDesigner) {
                this.filterObject.persistentFilterId = `grid-${this.dataObject.id}`;
            }
            if (this.dataObject.layoutManager) {
                const multiselectCol = this.dataColumns.getColumn('o365_MultiSelect')!;
                const initialColumns = [{ colId: multiselectCol.colId, pinned: multiselectCol.pinned, width: multiselectCol.width, hide: multiselectCol.hide ? true : undefined }, ...this.dataColumns.initialColumns];
                this.dataObject.layoutManager.initDataGrid({
                    dataGridControl: this,
                    // initialColumns: this.dataColumns.initialColumns
                    initialColumns: initialColumns
                });
                // if (multiselectCol) {
                //     this.dataObject.layoutManager.activeLayout?.modules.columns?.addColumnToBaseline(multiselectCol.colId, {
                //         hide: multiselectCol.hide
                //     });
                // }
            }

            this._sortOrderChangedCt = this.dataObject.once('SortOrderChanged', () => {
                this._sortOrderChanged = true;
                this._sortOrderChangedCt = undefined;
            });

            this._dataObjectCleanupTokens.push(this.dataObject.on('BeforeLoad', () => {
                if (this.dataObject?.clientSideFiltering) { return; }
                if (this._rowCountRequestOptions && this.dataObject?.dataHandler.getAbortControllerForRequest) {
                    const abortController = this.dataObject?.dataHandler.getAbortControllerForRequest(this._rowCountRequestOptions);
                    abortController?.abort();
                }
                this._rowCountRequestOptions = undefined;
            }));

            this._dataObjectCleanupTokens.push(this.dataObject.on('DataLoaded', (_data, pOptions) => {
                if (this.dataObject?.rowCount != null || this.dataObject?.clientSideFiltering) { return; }
                if (this.dataObject?.hasNodeData && this.dataObject.nodeData?.enabled) { return; }
                this._rowCountRequestOptions = {...pOptions, operation: 'rowcount', timeout: 5};
                this.dataObject!.recordSource.loadRowCount(this._rowCountRequestOptions).then(() => {
                    this._rowCountRequestOptions = undefined;
                });
            }));


            this.dataObject.filterObject.updateColumns(this.dataColumns.columns);

            if (this.fieldFilters) {
                this.dataObject.filterObject.setFieldFilters(this.fieldFilters);
            }

            const currentSortOrder = this.dataObject.recordSource.getSortOrder();
            currentSortOrder.forEach((order, index) => {
                const sortField = Object.keys(order)[0] as string;
                this.dataColumns.columns.filter(x => x.field === sortField || x.sortField === sortField).forEach(col => {
                    if (currentSortOrder.length > 1) {
                        col.sortOrder = index + 1;
                    }
                    col.sort = order[sortField];
                });
            });

            if (this.onDemandFields) {
                const vFields: string[] = [];
                this.dataColumns.visibleColumns.forEach(col => {
                    if (col.colId.startsWith('o365')) { return; }
                    if (col.unbound && !col.boundFields.length) { return; }

                    if (!col.unbound) {
                        vFields.push(col.name);
                    }

                    col.boundFields.forEach(field => vFields.push(field));
                })

                // @ts-ignore fields are extended through getters and setters
                if (vFields.indexOf('PrimKey') === -1 && this.dataObject.fields['PrimKsetSelectFieldsey'] != null) {
                    vFields.push('PrimKey');
                }
                if (this.dataObject.recordSource.setSelectFields) {
                    this.dataObject.recordSource.setSelectFields(vFields);
                } else {
                    this.dataObject.recordSource.selectFields = vFields;
                }
            }

            if (this.props.softDelete) {
                const column = this.dataColumns.getColumn('o365_Action');
                if (column != null) {
                    column.cellRendererParams = {
                        softDelete: this.props.softDelete ?? false,
                    };
                }
            }
            this._validateOnDemandFields();
        } else {
            this._selectionControl = new DataSelectionControl({
                data: this.props.data!,
                dataGridControl: this
            });
        }

        // Check if multiple columns have the same colId
        const colIds: Record<string, boolean> = {};
        let duplicateColId: string | null = null;
        const hasDuplicateColId = this.dataColumns.columns.some(col => {
            if (colIds[col.colId]) { duplicateColId = col.colId; return true; }
            colIds[col.colId] = true;
            return false;
        });
        if (hasDuplicateColId) {
            this._showToast(`[${this.id}]: Duplicate colId provided: ${duplicateColId} \nEvery column MUST have a unique colId`);
        }

        this.updateWatchTargetType();

        this._isFileTable = this.dataColumns.columns.findIndex(col => col.field == 'FileName') != -1;
    }

    /** Execute async constructor process. Called right after the control is wrapped in a Proxy so any property changes keep their reactivity */
    postCreateInit() {
        dataGridControlStore.set(this.id, this);

        if (!this.dataObject) {
            if (this.props.gridApi?.setCurrentIndex == null && this.props.data && this.props.data[this.state.currentIndex]) {
                this.props.data[this.state.currentIndex].current = true;
            }
        }

        if (this.dataObject) {
            if (this.dataObject.hasPropertiesData) {
                import('o365-data-properties').then(async () => {
                    if (this.dataObject?.propertiesData.initializationPromise) {
                        await this.dataObject.propertiesData.initializationPromise;
                    }
                    this.propertiesData.initialize();
                });
            }
        }

    }

    /** Reset the current layout. Will execute only when the grid has a DataObject with layouts enabled */
    resetLayout() {
        if (this.dataObject?.layoutManager) {
            this.dataObject.layoutManager.resetLayout();
        }
    }

    /** 
     * Inform the grid that columns have changed. This will execute layout tracking and onDemandFields.  
     * This is used by column watchers and shouuld not be executed manually without good reason. 
     */
    watchColumnChanges() {
        if (this.dataObject && this.onDemandFields) {
            const fields: string[] = [];
            this.dataColumns.visibleColumns.forEach(col => {
                if (col.colId.startsWith('o365')) { return; }
                if (col.unbound && !col.boundFields.length) { return; }

                if (!col.unbound) {
                    fields.push(col.name);
                }

                col.boundFields.forEach(field => fields.push(field));
            })
            this.dataObject.recordSource.manageAndLoadSelectFields(fields);
        }

        this.dataColumns.updateWidths();

        if (this.updateViewport) {
            this.updateViewport();
        }

        if (this.columnWatchDebounce) clearTimeout(this.columnWatchDebounce);
        this.columnWatchDebounce = window.setTimeout(() => {
            if (this.dataObject && this.dataObject.layoutManager) {
                if (this.dataColumns.columns.findIndex(col => Object.keys(col.propertyChanges).length !== 0) === -1) {
                    // Columns have no changes, no need to update layouts
                    return;
                }
                // TODO: Use new layout manager instead with module tracking
                this.dataObject.layoutManager.trackColumnChange();
                this.dataObject.layoutManager.saveLocalChanges();
                if (!this.dataObject.layoutManager.activeLayout?.canAutoSave) { return; }
                if (this.layoutSaveLocks.length > 0) {
                    Promise.all(this.layoutSaveLocks).then(_ => {
                        this.layoutSaveLocks = [];
                        this.dataObject?.layoutManager?.saveLayout({
                            includedModules: ['columns']
                        });
                    });
                } else {
                    this.dataObject.layoutManager.saveLayout({
                        includedModules: ['columns']
                    });
                }
            }
        }, 100);
    }

    /**
     * Aplly a columns layout on the grid.
     * @param layout ColumnsLayout
     */
    applyLayout(layout: any) {
        if (!layout) { return; }

        const sortable: any[] = [];

        this.dataColumns.columns.forEach((col: DataColumn) => {
            col.propertyChanges = {};
            const layoutlCol = layout[col.colId];
            if (!layoutlCol || (DataGridControl.isColumnSystem(col.colId) && col.colId !== 'o365_MultiSelect')) { return; }
            Object.keys(layout[col.colId]).forEach(key => {
                let parsedKey = key;
                switch (key) {
                    case 'hide':
                    case 'width':
                    case 'pinned':
                        parsedKey = `_${key}`;
                        break;
                    case 'order':
                        sortable.push([col, layoutlCol[key], layoutlCol.baseline]);
                        return;
                }
                this.dataColumns.setColumnProperty(col.colId, parsedKey, (layoutlCol as any)[key]);
            });
        });

        // Sort order overrides to ascending values
        // sortable.sort((a, b) => a[2] - b[2]);
        // sortable.sort((a, b) => a[0].order - b[0].order);
        sortable.sort((a, b) => a[1] - b[1]);
        sortable.forEach(col => {
            // Apply order overrides with no change tracking
            this.dataColumns.setColumnOrder(col[0], col[1], false, false);
        });


        let checkCount = sortable.length;

        // Check if all applied orders match, this is needed when dynamic columns are added to the grid
        // Should run only 1 cycle if no dynamic columns are present
        while (checkCount > 0) {
            const allMatch = sortable.every(col => {
                if (col[0].order === col[1]) {
                    return true;
                } else {
                    // Column order does not match with the override value
                    // Set it again and stop the current loop itteration
                    this.dataColumns.setColumnOrder(col[0], col[1], false, false);
                    return false;
                }
            })
            if (allMatch) {
                checkCount = 0;
            } else {
                checkCount--;
            }
        }


        this.dataColumns.updateWidths();
        this.dataColumns.updateColumnArrays();
    }

    /** Initialize the grid container element */
    initializeContainer(container: HTMLElement, skipEventBind = false, pGridContainer: HTMLElement | undefined) {
        // Scroll events
        const getMainList = (arr: NodeListOf<HTMLElement>) => arr[['top', 'above-filters'].includes(this.props.newRecordsPosition) ? (arr.length - 1) : 0];
        this.container = container;
        this.scopedContainer = pGridContainer;
        this.viewPort = getMainList(this.container.querySelectorAll(this._gridQuery(".o365-body-center-cols-viewport")));
        const gridBody = container.querySelector(this._gridQuery('.o365-grid-body', true)) as HTMLElement;
        const verticalViewport = gridBody.querySelector('.o365-body-center-viewport') as HTMLElement;

        this.showHeightScrollbar = verticalViewport.clientHeight !== verticalViewport.scrollHeight;
        this.viewportHeight = verticalViewport.clientHeight;

        if (!skipEventBind) {
            // Type of scroll containers:
            //  o365-body-center-cols-viewport: center columns in data lists (main grid list and new records list)
            //  o365-grid-container: no real scroll, uses transforms (band row, header row, filter row, footer row)
            //  o365-header-viewport: filter and header container that could potentially be scroleld through tab focus
            //  o365-body-horizontal-scroll-viewport: the main center scroll bar at the bottom of the grid
            let scrollDebounce: any;
            let transformScrollDebounce: number | null = null;
            let transformingScroll = false;

            gridBody.querySelectorAll<HTMLElement>('.o365-header-viewport').forEach((container) => {
                container.addEventListener('scroll', (e) => {
                    if (transformingScroll) { return; }
                    if (transformScrollDebounce) { window.clearTimeout(transformScrollDebounce); }
                    transformScrollDebounce = window.setTimeout(() => { transformingScroll = false; transformScrollDebounce = null; }, 10);
                    transformingScroll = true;
                    const target = e.target as HTMLElement;
                    const scrollbar = gridBody.querySelector<HTMLElement>(this._gridQuery(".o365-body-horizontal-scroll-viewport"));
                    if (scrollbar) {
                        scrollbar.scrollLeft = target.scrollLeft;
                    }
                    target.scrollLeft = 0;
                });
            });
            getMainList(gridBody.querySelectorAll(this._gridQuery('.o365-body-center-cols-viewport')))?.addEventListener('scroll', (e: any) => {
                // Scrolling from main list
                if (this.isScrollingFromScrollbar) { return; }

                if (scrollDebounce) { window.clearTimeout(scrollDebounce); }
                this.isScrollingFromMainContainer = true;
                scrollDebounce = window.setTimeout(() => {
                    this.isScrollingFromMainContainer = false;
                }, 100);

                const batchRecordsContainer = this.props.newRecordsPosition == 'bottom' 
                    ? gridBody.querySelector(this._gridQuery('[data-o365-container="N"] .o365-body-center-cols-viewport'))
                    : gridBody.querySelector(this._gridQuery('.o365-header .o365-body-center-cols-viewport'));
                if (batchRecordsContainer) {
                    batchRecordsContainer.scrollLeft = e.target.scrollLeft;
                }

                const scrollElement = gridBody.querySelector(this._gridQuery('.o365-body-horizontal-scroll-viewport')) as HTMLElement;
                scrollElement.scrollLeft = e.target.scrollLeft;

                gridBody.querySelectorAll<HTMLElement>(this._gridQuery(".o365-grid-container")).forEach((container) => container.style.transform = "translateX(-" + e.target.scrollLeft + "px)");
                this.updateWidthScrollState(e.target);
            });

            let scrollDebounce2: any;
            gridBody.querySelector<HTMLElement>(this._gridQuery(".o365-body-horizontal-scroll-viewport"))?.addEventListener("scroll", (e: any) => {
                // Scrolling from grid
                if (this.isScrollingFromMainContainer) { return; }

                if (scrollDebounce2) { window.clearTimeout(scrollDebounce2); }
                this.isScrollingFromScrollbar = true;
                scrollDebounce2 = window.setTimeout(() => {
                    this.isScrollingFromScrollbar = false;
                }, 100);

                const scrollLeft = e.target.scrollLeft;
                gridBody.querySelectorAll<HTMLElement>(this._gridQuery(".o365-body-center-cols-viewport")).forEach((container) => {
                    container.scrollLeft = scrollLeft;
                });
                // const newRecordsContainer = vContainer.querySelectorAll(".o365-header .o365-body-center-cols-viewport");
                gridBody.querySelectorAll<HTMLElement>(this._gridQuery(".o365-grid-container")).forEach((container) => {
                    container.style.transform = "translateX(-" + e.target.scrollLeft + "px)";
                });
                this.updateWidthScrollState(e.target);
            });
        }
        if (!this.isTable && !this.props.isLookup) {
            let previousWidth: number | null = null;
            let previousHeight: number | null = null;
            let heightChangeDebounce: number | null = null;

            this._intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    this._notVisible = !entry.isIntersecting;
                });
            });

            this._resizeObserver = new ResizeObserver((entries) => {
                entries.forEach(entry => {
                    const currentWidth = entry.contentRect.width;
                    const currentHeight = entry.contentRect.height;

                    // if (currentWidth !== previousWidth && previousWidth !== null) {
                    //     window.setTimeout(() => {
                    //         if (this.updateViewport) {
                    //             this.updateViewport();
                    //         }
                    //     }, 50);
                    // }

                    if (currentHeight !== previousHeight && previousHeight !== null) {
                        if (heightChangeDebounce) { window.clearTimeout(heightChangeDebounce) };
                        heightChangeDebounce = window.setTimeout(() => {
                            if (verticalViewport) {
                                this.showHeightScrollbar = verticalViewport.clientHeight !== verticalViewport.scrollHeight;
                                this.viewportHeight = verticalViewport.clientHeight;
                            }
                            if (this.updateVirtualScrollData) {
                                this.updateVirtualScrollData();
                            }
                            heightChangeDebounce = null;
                        }, 100);
                    }

                    previousWidth = currentWidth;
                    previousHeight = currentHeight;
                });
            });
            this.observeMainList();
            this.observeIntersections();
        }
        if (!this.props.disableColumnMove) {
            this.columnMove = new DataGridColumnMove(this);
            this.columnMove.initialize();
            // this.columnMove = new ColumnMove(gridBody, this.dataColumns, this.multilineHeader)
        }
        if (!this.props.disableNavigation) {
            this.navigation.initialize();
        }
        if (this.menuTabs) {
            this.menuTabs.containerInitialized();
        } 
    }

    setCurrentIndex(pIndex: number) {
        if (this.dataObject ? pIndex === this.dataObject.currentIndex : pIndex === this.state.currentIndex) { return; }
        if (this.props.gridApi?.setCurrentIndex) {
            this.props.gridApi.setCurrentIndex(pIndex);
        } else if (this.dataObject) {
            this.dataObject.setCurrentIndex(pIndex, false);
        } else if (this.props.data) {
            let prevItem: Partial<DataItemModel> | null = null
            if (this.state.currentIndex < 0 && this.newData) {
                prevItem = this.newData.data[this.newData.getInversedIndex(this.state.currentIndex)];
            } else {
                prevItem = this.props.data[this.state.currentIndex];
            }
                const previousIndex = this.state.currentIndex;
            this.state.currentIndex = pIndex;
            if (prevItem?.dataObjectId && prevItem.appId) {
                import('o365-dataobject').then(o365 => {
                    const dataObject = o365.getDataObjectById(prevItem.dataObjectId!, prevItem.appId);
                    dataObject.setCurrentIndex(pIndex);
                });
            } else {
                if (prevItem) {
                    prevItem.current = false;
                }
                let currentItem: Partial<DataItemModel> | null = null
                if (this.state.currentIndex < 0 && this.newData) {
                    currentItem = this.newData.data[this.newData.getInversedIndex(pIndex)];
                } else {
                    currentItem = this.props.data[this.state.currentIndex];
                }
                if (currentItem) {
                    currentItem.current = true;
                }
                nextTick().then(() => {
                    this.emit('CurrentIndexChanged', previousIndex, pIndex);
                });
            }
        }
    }

    save(pIndex?: number) {
        if (this.props.gridApi?.save) {
            return this.props.gridApi.save(pIndex ?? this.state.currentIndex);
        } else if (this.dataObject) {
            return this.dataObject.recordSource.save(pIndex, { skipErrorAlert: true }) ?? Promise.resolve([]);
        } else if (this.props.data) {
            const item = this.props.data[pIndex ?? this.state.currentIndex];
            if (item?.save) {
                return item.save();
            }
        }
        return Promise.resolve();
    }

    selectAll() {
        if (this.dataObject) {
            this.dataObject?.selectionControl.selectAll(!this.dataObject.selectionControl.allRowsSelected);
        } else if (this.props.data) {
            /* const allRowsSelected = this.props.data.every(item => item.isSelected);
            this.props.data.forEach(item => item.isSelected = !allRowsSelected); */
            this.selectionControl.selectAll(!this.selectionControl.allRowsSelected);
        }
    }

    cancelChanges(pIndex: number, pKey?: string) {
        this?.dataObject?.cancelChanges(pIndex, pKey);
    }

    initializeCellSelectControl(selectionRef: any) {
        // 🚩 TODO: Check if this is used. DataObject has the same control?
        // this.cellSelectionControl = new CellSelectControl({
        //     selection: selectionRef,
        //     dataColumns: this.dataColumns,
        //     dataObject: this.dataObject
        // });
    }

    load() {
        if (this.dataObject) {
            if (this.dataObject.treeify && this.dataObject.treeify.enabled) {
                // Load new data into treeify
                this.dataObject.treeify.disable();
                if (this.dataObject.filterObject.appliedFilterString != null) {
                    // Need to apply filter after getting new data only
                    const prevFilterString = this.dataObject.recordSource.filterString;
                    this.dataObject.recordSource.filterString = null;
                    this.dataObject.load().then(() => {
                        // Rerun clientside treeify with the filter
                        this.dataObject.recordSource.filterString = prevFilterString;
                        this.dataObject.load();
                    });
                } else {
                    this.dataObject.load();
                }
                this.dataObject.treeify.enable()
            } else if (this.dataObject.hasNodeData && this.dataObject.nodeData.enabled) {
                this.dataObject.storage.clearItems();
                this.dataObject.load();
            } else {
                this.dataObject.load();
            }
        } else if (this.props.gridApi?.load) {
            this.props.gridApi.load();
        }
    }

    /** Reset grid width scroll */
    resetWidthScroll() {
        const gridBody = this.container?.querySelector(this._gridQuery('.o365-grid-body', true)) as HTMLElement;
        if (gridBody == null) { return; }
        const widthScrollBar = gridBody.querySelector<HTMLElement>(this._gridQuery(".o365-body-horizontal-scroll-viewport"));
        if (widthScrollBar) {
            if (this.lastKnownWidthScroll) {
                widthScrollBar.scrollLeft = this.lastKnownWidthScroll;
            } else {
                widthScrollBar.scrollLeft = 1;
                widthScrollBar.scrollLeft = 0;
            }
        }
    }

    private _layoutReapplyDebounce: number | null = null;
    /** Apply current layout. Executed when adding dynamic columns */
    private _reapplyLayout() {
        if (!this.dataObject?.layoutManager) { return; }
        if (this._layoutReapplyDebounce) { window.clearTimeout(this._layoutReapplyDebounce); }
        this._layoutReapplyDebounce = window.setTimeout(() => {
            const layoutWithChanges = this.dataObject?.layoutManager?.getActiveLayoutStoredChanges('columns');
            if (layoutWithChanges) {
                    this.dataObject?.layoutManager?.activeLayout?.modules?.columns.apply(layoutWithChanges, true );
            } else {
                const currentLayout = this.dataObject?.layoutManager?.activeLayout?.layout?.columns;
                if (currentLayout) {
                    this.dataObject?.layoutManager?.activeLayout?.modules?.columns.apply(currentLayout);
                }
            }
            //this.dataObject?.layoutManager?.reapplyLayout();
        }, 10);
    }

    addColumn(columnDefinition: DataColumnOptions, index?: number) {
        if (!columnDefinition) { return; }
        if (index == null) {
            index = this.dataColumns.showActionColumn ? this.dataColumns.columns.length - 1 : this.dataColumns.columns.length
        }

        let localLayout = this.dataObject?.layoutManager?.activeLayout?.layoutToSave?.columns ?? this.dataObject?.layoutManager?.activeLayout?.layout?.columns ?? null;
        // console.log(localLayout);
        const addedColumns: DataColumn[] = [];

        const appendCol = (colDef: DataColumnOptions, colIndex: number) => {
            // Try to add datatype from dataobject fields
            if (this.dataObject) {
                const fieldName = colDef.field;
                if (fieldName != null && !fieldName.startsWith("o365_")) {
                    // const field = this.dataObject.fields.fields.filter(x => x.name === fieldName)[0];
                    const field = this.dataObject.fields[fieldName];

                    if (field == null) {
                        logger.warn(`Column recieved a field (${fieldName}) that does not exist does not exist in ${this.dataObject.id}`);
                        return;
                    }
                    if (field.type != null) {
                        colDef.type = field.type;
                    }
                    if (field.caption != null && colDef.headerName == null) {
                        colDef.headerName = field.caption;
                    }
                }
            }

            const dataColumn = new DataColumn(colDef);
            this.dataColumns.initialColumns.splice(Math.max(colIndex - 2, 0), 0, columnDefinition);
            dataColumn.order = this.dataColumns.columns.length;
            this.dataColumns.columns.splice(colIndex, 0, dataColumn);

            const storedLayoutChanges = this.dataObject?.layoutManager?.getActiveLayoutStoredChanges('columns');
            try {

                if (localLayout?.[dataColumn.colId] == null && (storedLayoutChanges?.[dataColumn.colId] ?? this.dataObject?.layoutManager?.activeLayout?.layout?.columns?.[dataColumn.colId])) {
                    // Column is in unsaved layout changed but not in current layout object. Add it back.
                    const columnLayout = storedLayoutChanges?.[dataColumn.colId] ?? this.dataObject?.layoutManager?.activeLayout?.layout?.columns?.[dataColumn.colId];
                    this.dataObject?.layoutManager?.activeLayout?.modules?.columns?.addColumnToCurrent(dataColumn.colId, columnLayout);
                    if (localLayout == null) { localLayout = {}; }
                    localLayout[dataColumn.colId] = columnLayout;
                }
            } catch (ex) {
                console.warn(ex);
            }

            if (dataColumn.parentGroupId) {
                // Column is inside a group(band). Add it into the group
                const group = this.dataColumns.getGroup(0, dataColumn.parentGroupId);
                group.children.push(dataColumn);
            }
            const order = colIndex;
            const proxyColumn = this.dataColumns.columns.find(x => x.colId === dataColumn.colId)!;

            if (this.dataObject?.layoutManager) {
                const baselineDef = {
                    width: columnDefinition.width ? parseInt(`${columnDefinition.width}`) : proxyColumn._width,
                    pinned: columnDefinition.pinned ?? undefined,
                    hide: columnDefinition.hide,
                    order: colIndex,
                    hideFromChooser: columnDefinition['hideFromChooser'],
                };
                this.dataObject.layoutManager.activeLayout?.modules.columns?.addColumnToBaseline(dataColumn.colId, baselineDef);
            }
            this.dataColumns.setColumnOrder(proxyColumn, order, false, false);
            this.dataColumns.addWatcher(proxyColumn);
            if (localLayout) {
                if (localLayout[proxyColumn.colId]) {
                    Object.entries(localLayout[proxyColumn.colId]).forEach(entires => {
                        if (entires[0] === 'order') { return; }
                        this.dataColumns.setColumnProperty(proxyColumn.colId, `_${entires[0]}`, entires[1]);
                    });
                }
                const shouldApply = Object.entries(localLayout).some(([key, def]) => {
                    return key === proxyColumn.colId || (def as any)['order'] != null;
                });
                if (shouldApply) { this._reapplyLayout(); }
            }
            addedColumns.push(proxyColumn);
        };

        if (Array.isArray(columnDefinition)) {
            columnDefinition.forEach((colDef, colIndex) => {
                appendCol(colDef, index! + colIndex);
            });
        } else {
            appendCol(columnDefinition, index!);
        }
        const actionCol = this.dataColumns.getColumn('o365_Action');
        if (actionCol) {
            if (actionCol.pinned == null && actionCol.order !== this.dataColumns.columns.length - 1) {
                this.dataColumns.setColumnOrder(actionCol, this.dataColumns.columns.length-1, false, false);
            }
        }
        this.dataColumns.updateColumnArrays();
        this.dataColumns.updateWidths();
        if (this.updateViewport) {
            this.updateViewport();
        }
        if (this.dataObject) {
            this.dataObject.filterObject.updateColumns(addedColumns);
        } else {
            this.filterObject.updateColumns(addedColumns);
        }
        this.eventHandler.emit('ColumnAdded');
    }

    removeColumn(colId: string) {
        if (!colId) { return; }
        let indexToRemove: number | null = null;
        const notFound = this.dataColumns.columns.every((col: DataColumn, index: number) => {
            if (col.colId === colId) {
                indexToRemove = index;
                return false;
            } else {
                return true;
            }
        });
        if (notFound) { return; }
        if (this.dataObject?.layoutManager) {
            this.dataObject.layoutManager.activeLayout?.modules.columns?.removeColumnFromBaseline(colId);
        }

        const dataColumn = this.dataColumns.columns[indexToRemove!];
        if (dataColumn.parentGroupId) {
            const group = this.dataColumns.getGroup(0, dataColumn.parentGroupId);
            // @ts-ignore
            const groupIndex = group.children.findIndex(col => col.colId == colId);
            if (groupIndex != null && groupIndex !== -1) {
                group.children.splice(groupIndex, 1);
            }
        }

        const getId = (col: any) => col.colId ?? col.column ?? col.field;
        let initialColIndex: number | null = null;
        this.dataColumns.initialColumns.every((col: any, index: number) => {
            if (getId(col) === colId) {
                initialColIndex = index;
                return false;
            } else {
                return true;
            }
        });
        if (initialColIndex != null) {
            this.dataColumns.initialColumns.splice(initialColIndex, 1);
        } else {
            console.warn(`Could not remove column from initial ${dataColumn.colId}`)
        }
        this.dataColumns.columns.splice(indexToRemove!, 1);
        this.dataColumns.updateColumnArrays();
        this.dataColumns.updateWidths();
        this.dataColumns.removeWatcher(colId);
        if (this.updateViewport) {
            this.updateViewport();
        }
        this.eventHandler.emit('ColumnRemoved');
    }

    clearSelection() {
        if (this.hasNavigation) {
            if (this.gridFocusControl) {
                if (this.gridFocusControl.editMode) {
                    this.gridFocusControl.exitEditMode();
                }
                this.navigation.resolveFocus();
                // if (!['G', 'N'].includes(this.navigation.activeCell?.container as any)) {
                //     this.gridFocusControl.clearFocus();
                // }
            }

            if (this.gridSelectionInterface) {
                this.gridSelectionInterface.clearSelection()
            }
        }
    }

    /** Function used by dropdowns to pass Tab and Enter keydowns from lookups to the grid */
    handleDropdownKeydown(e: KeyboardEvent, targetRef: { value: HTMLElement }) {
        if (e.key !== 'Tab' && e.key !== 'Escape') { return; }

        const target = <HTMLElement>e.target
        const lookup = target?.closest('.o365-data-lookup');
        if (lookup) {
            const dataGridId = lookup.querySelector<HTMLElement>('.o365-data-grid')?.id;
            if (dataGridId) {
                const dataGridControl = getDataGridControlById(dataGridId);
                if (dataGridControl) {
                    const cell = target.closest<HTMLElement>('[data-o365-colindex]');
                    const colIndex = cell?.dataset.o365Colindex;
                    if (colIndex) {
                        const didFocus = e.shiftKey
                            ? dataGridControl.header?.focusPreviousFilterCell(+colIndex)
                            : dataGridControl.header?.focusNextFilterCell(+colIndex);
                        if (didFocus) {
                            return;
                        }
                    }
                }
            }
            e.stopPropagation();
            e.preventDefault();
            targetRef.value.closest('.o365-body-cell')?.dispatchEvent(new KeyboardEvent('keydown', { shiftKey: e.shiftKey, key: e.key, bubbles: true }));
        }
    }

    updateWatchTargetType() {
        if (this.dataObject == null) {
            this._currentWatchTargetType = 'arrayData';
        } else if (this.props.treeColumnDefinition) {
            this._currentWatchTargetType = 'treeify';
        } else if (this.props.groupBy && this.dataObject.groupBy?.enabled) {
            this._currentWatchTargetType = 'groupBy';
        } else if (this.props.nodeData) {
            this._currentWatchTargetType = 'nodeData';
        } else {
            this._currentWatchTargetType = 'dataObjectStorage';
        }
    }

    /**
     * Update the current state of scroll position from the scroller element
     */
    updateWidthScrollState(scrollElement: HTMLElement) {
        if (!scrollElement || scrollElement.scrollWidth - scrollElement.clientWidth === 0) {
            this.widthScrollState = DataGridWidthScrollState.None;
        } else if (scrollElement.scrollLeft === 0) {
            this.widthScrollState = DataGridWidthScrollState.Start;
        } else if (scrollElement.scrollLeft === (scrollElement.scrollWidth - scrollElement.clientWidth)) {
            this.widthScrollState = DataGridWidthScrollState.End;
        } else {
            this.widthScrollState = DataGridWidthScrollState.None;
        }
        if (scrollElement?.clientWidth) {
            this.lastKnownWidthScroll = scrollElement?.scrollLeft;
        }
    }

    // temp dts fix
    // handleScrollItemMultiSelect(pEvent: PointerEvent, pRow: ScrollItem) {
    handleScrollItemMultiSelect(pEvent: PointerEvent, pRow: any) {
        const target = pEvent.target as HTMLInputElement;
        if (pRow.item) {
            pRow.item.isSelected = target.checked;
            if (this.props.data !== null) {
                this.selectionControl?.onSelection(pRow, target.checked)
            }
            if (this._lastMultiSelectIndex != null && pEvent.shiftKey) {
                const setTo = pRow.item.isSelected;
                const currentIndex = pRow.index;
                const lastIndex = this._lastMultiSelectIndex;
                const start = currentIndex < lastIndex ? currentIndex : lastIndex;
                const end = currentIndex < lastIndex ? lastIndex : currentIndex;
                if (this.dataObject) {
                    this.dataObject.selectionControl.selectRange(setTo, start, end);
                } else if (this.props.data) {
                    this._selectionControl?.selectRange(setTo, start, end);
                }

            }
            this._lastMultiSelectIndex = pRow.index;
        }
    }

    //--- EventEmitter ---
    on<K extends keyof DataGridEvents>(event: K, listener: DataGridEvents[K]) {
        return this.eventHandler.on(event, listener);
    }
    once<K extends keyof DataGridEvents>(event: K, listener: DataGridEvents[K]) {
        return this.eventHandler.once(event, listener);
    }
    off<K extends keyof DataGridEvents>(event: K, listener: DataGridEvents[K]) {
        return this.eventHandler.off(event, listener);
    }
    // emit<K extends keyof DataGridEvents>(event: K, ...args: EventArgType<DataGridEvents, K>) {
    //     return this.eventHandler.emit(event, ...args);
    // }
    emit<K extends keyof DataGridEvents>(event: K, ...args: any[]) {
        // @ts-ignore temp dts fix
        return this.eventHandler.emit(event, ...args);
    }

    removeAllListeners = () => this.eventHandler.removeAllListeners();

    /**
     * Returns the grid group by contorl
     */
    async getGroupBy(): Promise<never | null> {
        if (this.groupBy) {
            return Promise.resolve(this.groupBy);
        } else if (this._groupByPromise) {
            await this._groupByPromise;
            return Promise.resolve(this.groupBy!);
        }
        return Promise.resolve(null);;
    }

    /**
     * Enable auto new row for DataTable.
     * WARNING: function subject to change
     */
    enableAutoNewRow() {
        if (!this.isTable || this._cancelAutoNewRow || this.dataObject == null) { return; }
        this._cancelAutoNewRow = this.dataObject.on('FieldChanged', () => {
            const emptyItems = this.dataObject!.storage.data.filter(item => item.isNewRecord && !item.hasChanges);
            if (emptyItems.length === 0) {
                this.dataObject!.createNew();
            } else if (emptyItems.length > 1) {
                let index = emptyItems.at(-1)!.index;

                const previousIsEmpty = this.dataObject!.storage.data[index - 1]?.isEmpty;
                if (!previousIsEmpty) {
                    index = emptyItems[0]!.index;
                }
                const item = this.dataObject!.storage.data[index];
                this.dataObject!.storage.removeItem(index);
                if (item.current) {
                    if (this.dataObject!.storage.data[index] != null) {
                        this.dataObject!.setCurrentIndex(index, true);
                    } else if (index - 1 >= 0 && this.dataObject!.data[index - 1] != null) {
                        this.dataObject!.setCurrentIndex(index - 1, true);
                    } else {
                        this.dataObject!.unsetCurrentIndex();
                    }
                } else if (this.dataObject!.currentIndex != null && this.dataObject!.currentIndex > index) {
                    // Items shifted, update current index without state changes
                    this.dataObject!.updateCurrentIndex(this.dataObject!.currentIndex + 1);
                }
            }
        });
    }

    /**
     * Disable auto new row for DataTable
     * WARNING: function subject to change
     */
    disableAutoNewRow() {
        if (this._cancelAutoNewRow) {
            this._cancelAutoNewRow();
        }
    }


    /**
     * Enables batch records and creates new empty record.
     */
    enableBatchRecords(pPosition?: IDataGridControlProps['newRecordsPosition']) {
        this.autoNewRecordsPanel = false;
        if (this.createNewOverrideFn) {
            return this.createNewOverrideFn(this.dataObject);
        } else if (this.dataObject) {
            if (pPosition) {
                this._storeNewRecordsOverride(pPosition);
                this._newRecordsPosition = pPosition;
            }
            this.dataObject?.batchData;
        }
    }

    /**
     * Disables batch records and closes new records panel
     */
    closeBatchRecords() {
        if (this.dataObject?.batchDataEnabled) {
            const hasSavedRows = this.dataObject.batchData.data.some(row => !row.isNewRecord);
            this.dataObject.batchData.disableBatchData();
            if (this.hasNavigation) {
                this.navigation.exitEditMode();
                this.navigation.resolveFocus();
            }
            if (hasSavedRows) {
                this.dataObject.load();
            }
        }
    }

    /**
     * Run all the clean up tasks
     */
    destroy() {
        this.dataColumns.removeWatchers();
        this.removeAllListeners();

        this.emit('Unmounted');

        this.closeBatchRecords();
        if (dataGridControlStore.has(this.id)) {
            dataGridControlStore.delete(this.id);
        }
        if (this._resizeObserver) {
            this._resizeObserver.disconnect();
        }
        if (this.hasNodeData) {
            this.nodeData.destroy();
        }
        if (this._cancelAutoNewRow) {
            this._cancelAutoNewRow();
        }

        if (this._resizeObserver) {
            this._disconnectObserver();
        }

        if (this._dataObjectCleanupTokens) {
            const tokens = this._dataObjectCleanupTokens.splice(0, this._dataObjectCleanupTokens.length);
            tokens.forEach(ct => ct());
        }

        if (this._intersectionObserver) {
            this._intersectionObserver.disconnect();
            this._intersectionObserver = undefined;
        }
        this._cancelTokens.forEach(ct => ct());
    }

    private _asyncAddColumnDebounce?: number;
    private _columnsToAdd?: { colDef: DataColumnOptions, index: number, res: () => void }[];
    /** Add a column asynchronously in the order of its place in the definition */
    asyncAddColumn(colDef: DataColumnOptions, index: number): Promise<void> {
        if (this._asyncAddColumnDebounce) { window.clearTimeout(this._asyncAddColumnDebounce); }
        return new Promise((res) => {
            if (this._columnsToAdd == null) { this._columnsToAdd = []; }
            this._columnsToAdd.push({ colDef: colDef, index: index, res: res });
            this._asyncAddColumnDebounce = setTimeout(() => {
                this._addAllAsyncColumns();
                this._asyncAddColumnDebounce = undefined;
                this._columnsToAdd = undefined;
            }, 32);
        });
    }

    private _addAllAsyncColumns() {
        if (this._columnsToAdd == null) { return; }

        this._columnsToAdd.sort((a, b) => a.index - b.index);
        this._columnsToAdd.forEach(options => {
            this.addColumn(options.colDef, options.index);
            options.res();
        });

        const colIds: Record<string, boolean> = {};
        let duplicateColId: string | null = null;
        const hasDuplicateColId = this.dataColumns.columns.some(col => {
            if (colIds[col.colId]) { duplicateColId = col.colId; return true; }
            colIds[col.colId] = true;
            return false;
        });
        if (hasDuplicateColId) {
            this._showToast(`[${this.id}]: Duplicate colId provided: ${duplicateColId} \nEvery column MUST have a unique colId`);
        }

    }

    observeIntersections() {
        if (this._intersectionsObserved || this._intersectionObserver == null || this.container == null) { return; }
        this._intersectionsObserved = true;
        this._intersectionObserver.observe(this.container);
    }
    unobserveIntersections() {
        if (!this._intersectionsObserved || this._intersectionObserver == null || this.container == null) { return; }
        this._intersectionsObserved = false;
        this._intersectionObserver.unobserve(this.container);
    }

    observeMainList() {
        if (this._isObserved || this._resizeObserver == null) { return; }
        this._isObserved = true;
        const gridBody = this.container?.querySelector(this._gridQuery('.o365-grid-body', true)) as HTMLElement;
        const mainListViewport = gridBody?.querySelector('.o365-main-list');
        if (mainListViewport && this._resizeObserver) {
            this._resizeObserver.observe(mainListViewport);
        }
    }
    unobserveMainList() {
        if (!this._isObserved || this._resizeObserver == null) { return; }
        this._isObserved = false;
        const gridBody = this.container?.querySelector(this._gridQuery('.o365-grid-body', true)) as HTMLElement;
        const mainListViewport = gridBody?.querySelector('.o365-main-list');
        if (mainListViewport && this._resizeObserver) {
            this._resizeObserver.unobserve(mainListViewport);
        }
    }

    private _disconnectObserver() {
        if (this._resizeObserver) {
            try {
                this._resizeObserver.disconnect();
            } catch (ex) {
                console.warn(ex);
            }
        }
    }

    /** Show a global toast */
    private _showToast(pMessage: string, pType = 'danger', pAutoHide?: boolean) {
        import('o365-vue-services').then(alertModule => {
            alertModule.alert(pMessage, pType, pAutoHide ? { autohide: true } : undefined);
        });
    }

    _gridQuery(query: string, isBody = false) {
        if (isBody) {
            return `.grid-${this.uid}${query}`;
        } else {
            return `.grid-${this.uid} ${query}`;
        }
    }

    private _validateOnDemandFields() {
        if (!this.props.onDemandFields || this.dataObject == null) { return; }
        let warnings: string[] = [];

        this.dataColumns.columns.forEach(col => {
            if (col.unbound || this.dataObject!.fields[col.field] == null) { return; }
            if (col.sortField && col.field !== col.sortField) {
                let isLoaded = !!this.dataColumns.columns.find(x => x.field === col.sortField);
                if (!isLoaded) {
                    isLoaded = this.dataObject!.fields.loadAlways.includes(col.sortField);
                }
                if (!isLoaded) {
                    isLoaded = this.dataObject!.fields[col.field]!.dependantFields.includes(col.sortField);
                }
                if (!isLoaded) {
                    this.dataObject!.fields[col.field]!.dependantFields.push(col.sortField);
                    warnings.push(`Column ${col.colId} has sortField ${col.sortField} which is not a dependant of ${col.field} and is not included in fields.loadAlways.\nDue to onDemandFields it will be automaticly pushed as a depandancy`);
                }
            }
        });

        if (warnings.length > 0) {
            warnings.forEach(warning => {
                logger.warn(warning);
            });
        }
    }

    private _storeNewRecordsOverride(pValue: string) {
        localStorageHelper.setItem('datagrid-newrecords-position', pValue, {
            expirationDays: 30,
            global: false
        })
    }
}


/** Grrid helper class  */
class DataGridState {
    private _dataObject?: DataObject;

    private _allowUpdate: boolean = true;
    get allowUpdate() {
        return this._dataObject?.allowUpdate ?? this._allowUpdate;
    }
    set allowUpdate(value: boolean) {
        if (!this._dataObject) {
            this._allowUpdate = value;
        }
    }
    private _allowInsert: boolean = false;
    get allowInsert() {
        return this._dataObject?.allowInsert ?? this._allowInsert;
    }
    set allowInsert(value: boolean) {
        if (!this._dataObject) {
            this._allowInsert = value;
        }
    }

    private _allowDelete: boolean = false;
    get allowDelete() {
        return this._dataObject?.allowDelete ?? this._allowDelete;
    }
    set allowDelete(value: boolean) {
        if (!this._dataObject) {
            this._allowDelete = value;
        }
    }

    private _isLoading: boolean = false;
    get isLoading() {
        return this._dataObject?.state.isLoading ?? this._isLoading;
    }
    set isLoading(value: boolean) {
        if (!this._dataObject) {
            this._isLoading = value;
        }
    }

    private _isRowCountLoading: boolean = false;
    get isRowCountLoading() {
        if (this._dataObject) {
            return this._dataObject.state.isRowCountLoading || (this._dataObject.recordSource.maxRecords === -1 && this._dataObject.state.isLoading);
        } else {
            return this._isRowCountLoading;
        }
    }
    set isRowCountLoading(value: boolean) {
        if (!this._dataObject) {
            this._isRowCountLoading = value;
        }
    }

    private _isNextPageLoading: boolean = false;
    get isNextPageLoading() {
        return this._dataObject?.state.isNextPageLoading ?? this._isNextPageLoading;
    }
    set isNextPageLoading(value: boolean) {
        if (!this._dataObject) {
            this._isNextPageLoading = value;
        }
    }

    private _disableSaveOncurrentIndexChange: boolean = false;
    get disableSaveOncurrentIndexChange() {
        return this._dataObject?.disableSaveOncurrentIndexChange ?? this._disableSaveOncurrentIndexChange;
    }
    set disableSaveOncurrentIndexChange(value) {
        if (!this._dataObject) {
            this._disableSaveOncurrentIndexChange = value;
        }
    }

    private _currentIndex: number|null = null;
    get currentIndex() {
        return this._dataObject?.currentIndex ?? this._currentIndex;
    }
    set currentIndex(value) {
        if (!this._dataObject) {
            this._currentIndex = value;
        }
    }

    constructor(pDataObject?: DataObject) {
        this._dataObject = pDataObject;
    }
}

/** Grid utility classs for optional modules */
class DataGridUtils {
    applySort?: () => void;
    /** Length of the data array */
    rowCount?: number;
    /** Length of the filtered data array */
    filteredRowCount?: number;
    processedData?: any[];
    openMenuModal(_pMenu: 'filters' | 'columns' | 'export' | 'layouts') {
        logger.warn('Grid dropdown menu is not mounted');
    };
    setRowHover(_pIndex?: number | null) {
        logger.warn('Something went wrong. Row hover composable is not initialized');
    }
};

export type DataGridEvents = {
    'ColumnAdded': () => void;
    'ColumnRemoved': () => void;
    'CurrentIndexChanged': (pPrevIndex: number, pNewIndex: number) => void;
    'Unmounted': () => void;
    'SubRegionResizing': () => void;
    'SidePanelResizing': () => void;
    'RowHover': (pIndex: number) => void;
    'RowHoverLeave': (pIndex: number) => void;
};

export type DataGridValueWatcherType = 'arrayData' | 'treeify' | 'groupBy' | 'nodeData' | 'dataObjectStorage';

export enum DataGridWidthScrollState {
    None = '',
    Start = 'start',
    End = 'end'
};

/** Helper class for generating grid ids from DataObjects */
function getDataGridIdFromDataObject(pDataObject: DataObject) {
    return `${pDataObject.appId}_${pDataObject.id}_grid`;
}


/** Copy of the grid props interface to get propper intellisense */
export interface IDataGridControlProps {
    dataObject?: DataObject,
    data?: Partial<DataItemModel>[],
    /** Columns passed as an object instead of slots */
    columns?: Record<string, any>[],
    /** String, dynamic class object or function that will be bound to the row class property. The current row is provided to the function as an argument */
    rowClass: any,
    /** String, dynamic class object or function that will be bound to the row style property. The current row is provided to the function as an argument */
    rowStyle: any,
    /**
    * The url to be used in the details iframe tab
    * @example `${site.oldGenUrl}/workflow-item?ID=${dsItems.current.ID}&HideNav=true`
    */
    detailIframe?: string,
    /** Optional id to enable the message channel on the detail iframe  */
    detailMessageChannelId?: string,
    /** Map of functions callable by the iframe message channel */
    detailMessageChannelFunctions?: any,
    /** The label used on the detail iframe tab */
    detailTabTitle?: string,
    /** The initial width on the sidepanel menu */
    initialMenuWidth?: string,
    /** When set to true will not render header */
    noHeader?: boolean,
    /** When set to true will not render header row but will still render the header container */
    noHeaderRow?: boolean,
    /** Enables word wrapping for the header row */
    multilineHeader?: boolean,
    /** When set to true will not render multi-select column */
    hideMultiselectColumn?: boolean,
    /** When set to true will not render action column */
    hideActionColumn?: boolean,
    /** When set to true will not render system column (current row indicator) */
    hideSystemColumn?: boolean,
    /** When set to true will stylize the active (current) row */
    activeRows?: boolean,
    /** Position where new records should be rendered. Either can be at the top or bottom */
    newRecordsPosition: 'top' | 'bottom' | 'above-filters',
    // TODO: Merge these together
    /** When set to true will disable the new record button and new records container */
    disableBatchRecords?: boolean,
    /** When set to true will skip rendering of the new record rows */
    hideNewRecords?: boolean,
    /** When set to `true` will not render the grid sidepanel menu */
    hideGridMenu?: boolean,
    /** When set to `true` the grid setup menu will be initially collapsed */
    collapseGridMenu?: boolean,
    /** Optional max-width setting for the grid menu (in px or %) */
    gridMenuMaxWidth?: string
    /** When set to `true` will disable grid navigation features */
    disableNavigation?: boolean,
    /** Select list will contain only visible grid columns and sort order columns set on data object */
    onDemandFields?: boolean,
    /** When set to true the grid will load the dataobject after mount */
    loadDataObject?: boolean
    // /** Use delete confirm for delete actions. Is true by default */
    // disableDeleteConfirm: boolean, 
    /** Use soft delete for ActionDelete in grid */
    softDelete?: boolean,
    /** When set to false will not render filter row */
    disableFilterRow?: boolean,
    /** When set to 'true' will disable sorting data from grid */
    disableSorting?: boolean,
    /** Use group by folders */
    groupByFolders?: boolean,
    /** An array of initial field filters. For example `['Title', {name:'StatusCode', distinct:'StatusCode'}]` */
    fieldFilters?: Array<string | { name: string, distinct: string }>,
    /**
    * An array of custom tab definitions for the grid sidemenu details tab
    * @example [
    *   { title: 'Custom Tab', id: 'tab1', iconClass: 'bi bi-1-square-fill', component: MyTabComponent}
    * ]
    */
    menuTabs?: Record<string, any>[],
    /** Enables grouping in the grid, can be passed as options object or as boolean for default configuration */
    groupBy?: object | boolean,
    /** Will disable the group by container, used when you don't want to allow the user to change group by settings */
    noGroupByContainer?: boolean,
    /** The column definition used when grouping is enabled for the Group column */
    groupColumnDefinition?: any,
    /** The column definition used to render TreeColumn when treeify is enabled on the provided dataobject */
    treeColumnDefinition?: any,
    /** Initial number of items to render for visual scroll. */
    itemsToRender?: number,
    /** @ignore*/
    rowHeight: number,
    /**
    * Enables dynamic loading for the grid. When set to false will set the inner height to loaded data length.
    * When using Tree or GroupBy default is 'false' otherwise will be 'true'
    */
    dynamicLoading?: boolean,
    /** When true will load ImportData */
    importData?: boolean,
    importDataProps?:object,
    /** Override create new record function */
    createNewOverrideFn?: Function,
    /**
    * Override the row click handler, when provided will not set current index
    * @param {DataItemModel} row - the row that was clicked on
    * @param {MouseEvent} e - the click event 
    * @example (row, e) => dsTest.setCurrentIndex(row.index)
    */
    rowclickhandler?: Function,
    /** Returns grid control ref immediately after creation */
    eagerGridControl?: Function,
    /** @ignore */
    isLookup?: boolean,
    /**
    * GroupBy options
    * @ignore
    */
    groupByOptions?: object,
    /** @ignore */
    useLeftColumnSizers?: boolean,
    showNewRecordsPanel?: boolean,
    /** Optional object with functions for overriding different grid functionalities */
    gridApi?: {
        setCurrentIndex?: (pIndex: number) => void;
        load?: () => Promise<void>;
        save?: (pIndex: number) => Promise<void>;
        /** 
         * When used with array data grid, this will enable new records support. Must 
         * return a new empty item.
         */
        createNew?: () => Partial<DataItemModel>;
    },
    nodeData?: {
        /** 
         * Field used for displaying values in the group column for expanded rows,
         * can be changed per group in group by level configurations.
         */
        displayField: string;
        getDisplay?: (row: any) => string;
        /**
         * Indent for rows set with this formula: `${rowLevel * indent}px`
         * @default 24
         */
        indent?: number;
        /** Optional settings for the added group column definition */
        column?: {
            headerName?: string;
            headerTitle?: string;
            editable?: boolean;
            /** Default is on the display field, pass false to disable or custom filter component */
            filter?: any | boolean;
            /** @default 400 */
            width?: number;
            pinned?: 'left' | 'right';
            cellTitle?: string;
            boldDisplay?: boolean;
        };
    }
    /** Disable column reordering */
    disableColumnMove?: boolean;
    /** Options related to grid context menu */
    contextMenu?: {
        /**
         * Optional funcction to manipulate values used by 'Filter By Selection' and 
         * 'Filter By Excluding Selection'. Used when diffrent filter fields are used from the main field.
         */
        resolveFilterValues?: (pRow: Record<string, any>) => Record<string, any>;
    },
    /** Message object that will be posted to the detail iframe whenever it changes  */
    detailMessage?: any,
    /** Api object for overriding various new records functionalities */
    newRecordsApi?: {
        focusFirstEditableCell?: (pGridControl: DataGridControl) => void;
    },
    /** When provided will enable support for persistent filters on the filter object */
    persistentFilterId?: string
    /** When true will always reload the summary row values on any data reload */
    alwaysReloadSummaryRow?: boolean;
    hideMenuItems?: string[];
    /** When true will exclude this grid from global click handler */
    disableSaveOnOutsideClicks?: boolean;
};

/** Helper class for supporting new records in array data grids */
class ArrayNewData {
    private _data: Partial<DataItemModel>[] = [];
    private _getGridContorl: () => DataGridControl;

    /** Array of new data */
    get data() { return this._data; }

    constructor(pOptions: {
        dataGridControl: DataGridControl
    }) {
        this._getGridContorl = () => pOptions.dataGridControl;
        if (pOptions.dataGridControl.props.gridApi?.createNew) {
            const item = pOptions.dataGridControl.props.gridApi.createNew();
            if (item.index == null) {
                item.index = -1;
            }
            this._data.push(item);
        }
    }

    /** Merge data from new items array into the main array */
    mergeData() {
        const indexesToMerge = [];
        this._data.forEach((item, index) => {
            if (!item.isNewRecord) {
                indexesToMerge.push(index);
            }
        });
    }

    /** Helper function to convert between negative and storage indexes */
    getInversedIndex(pIndex: number) {
        const index = (pIndex + 1) * -1;
        return index ? index : 0;
    }
}

// export type ColumnsLayout = Record<string, ILayoutColumnProperties>;

export { DataGridControl }
